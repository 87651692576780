import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

import MainLayout from "../layouts/MainLayout";

import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";
import AuthWrapper from "../AuthWrapper";

const REQUEST_BASE_URL = `${process.env.GATSBY_API_BASE_URL}/api/v1/`;
const REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
};

export default class collections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      errors: null,
      processing: false,
      email: "",
      password: "",
      token: "",
      msg: "",
    };

    this.validator = new SimpleReactValidator();
  }

  // Proceed to next step
  continues = (e) => {
    e.preventDefault();
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  back = (e) => {
    e.preventDefault();
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleProcessing = (processing = false, errors = null) => {
    this.setState({ processing, errors });
  };
  componentDidMount() {
    if (this.props.location.search.includes("?token")) {
      const params = new URLSearchParams(this.props.location.search);
      const token = params.get("token");
      this.setState({ token: token, step: 2 });
    }
  }

  submitForm = async (request) => {
    if (this.validator.allValid()) {
      this.handleProcessing(true);
      const { email, password, token } = this.state;
      const method = request;
      const headers = REQUEST_HEADERS;
      let url = `${REQUEST_BASE_URL}/marketplace/resetpassword`;
      if(method === "PUT") url= `${REQUEST_BASE_URL}/users/resetpassword`;

      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify({
          email,
          password,
          token,
          url: this.props.location.host,
        }),
      });
      if (response.ok) {
        if (method === "POST") {
          this.setState({
            step: 3,
            msg:
              "Password reset details are sent to your email at:" +
              this.state.email,
          });
        } else if (method === "PUT") {
          this.setState({
            step: 3,

            msg: "Password has been reset successfully",
          });
        }

        this.handleProcessing();
      } else {
        const err = await response.json();
        console.log(err);
        this.handleProcessing(false, err.msg);
        return;
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { step } = this.state;

    return (
      <LoadingOverlay>
        <Loader text="Processing ..." loading={this.state.processing} />

        <MainLayout>
          <AuthWrapper isSignUp={true}>
            <Seo title="Reset Password" />
            <div className="main">
              <h1 className="d-none">Forget Password</h1>
              <PageHeader title="Forget Password" />
              <Breadcrumb />

              <div className="page-content">
                <div className="container">
                  <hr className="mb-4" />
                  <div
                    className="login-page  pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backgrounds/login-bg.jpg)`,
                    }}
                  >
                    <div className="container">
                      <div className="form-box">
                        <div className="form-tab">
                          <div className="tab-content">
                            <div>
                              {" "}
                              <h5>Reset your account password</h5>
                              {this.state.errors && (
                                <div className="row">
                                  <div className="col">
                                    <div
                                      className="alert alert-danger w-100"
                                      role="alert"
                                    >
                                      {typeof this.state.errors === "string" &&
                                        this.state.errors}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>{" "}
                            {this.state.step === 1 ? (
                              <>
                                <form action="#">
                                  <div className="form-group">
                                    <label htmlFor="singin-email-2">
                                      Email address *
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="singin-email-2"
                                      name="email"
                                      onChange={this.handleChange("email")}
                                      defaultValue={this.state.email}
                                      required
                                    />
                                  </div>
                                </form>

                                <div className="form-footer">
                                  <button
                                    onClick={() => this.submitForm("POST")}
                                    type="submit"
                                    className="btn btn-outline-primary-2"
                                  >
                                    <span>Reset</span>
                                    <i className="icon-long-arrow-right"></i>
                                  </button>

                                  {/* <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="register-policy-2"
                                    required
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="register-policy-2"
                                  >
                                    I agree to the{" "}
                                    <a href="pages/login/#a">privacy policy</a>{" "}
                                    *
                                  </label>
                                </div> */}
                                </div>
                              </>
                            ) : step === 2 ? (
                              <>
                                <form action="#">
                                  <div className="form-group">
                                    <label htmlFor="singin-email-2">
                                      New Password *
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="singin-email-2"
                                      name="password"
                                      onChange={this.handleChange("password")}
                                      defaultValue={this.state.password}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="singin-email-2">
                                      Confirm Password *
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="singin-email-2"
                                      name="confirmpassword"
                                      onChange={this.handleChange(
                                        "confirmPassword"
                                      )}
                                      defaultValue={this.state.confirmPassword}
                                      required
                                    />
                                  </div>
                                </form>

                                <div className="form-footer">
                                  <button
                                    onClick={() => this.submitForm("PUT")}
                                    type="submit"
                                    className="btn btn-outline-primary-2"
                                  >
                                    <span>Reset</span>
                                    <i className="icon-long-arrow-right"></i>
                                  </button>

                                  {/* <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="register-policy-2"
                                required
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="register-policy-2"
                              >
                                I agree to the{" "}
                                <a href="pages/login/#a">privacy policy</a>{" "}
                                *
                              </label>
                            </div> */}
                                </div>
                              </>
                            ) : step === 3 ? (
                              <>{this.state.msg}</>
                            ) : (
                              "Failed"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AuthWrapper>
        </MainLayout>
      </LoadingOverlay>
    );
  }
}
